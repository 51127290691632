function Gnav_A(opts){
  console.group("Gnav_A");
  _.bindAll(this, "switch1enter", "switch1leave", "switch2enter", "switch2leave");
  this.opts = _.extend({
    duration: 200
  }, opts);
  
  this.$gnav_pc = $("#gnav_pc");
  this.$switch1 = this.$gnav_pc.find(".js_gnav_switch1");
  this.$switch2 = this.$gnav_pc.find(".js_gnav_switch2");
  this.$switch1__target = this.$gnav_pc.find(".js_gnav_switch1__target");
  this.$switch2__target = this.$gnav_pc.find(".js_gnav_switch2__target");
  
  this.switch1enterTimer = false;
  
  this.$gnav_pc.find(".g_nav_1__1 a").not(".js_gnav_switch1").on("mouseenter", this.switch1leave);
  //this.$gnav_pc.find(".g_nav_2__2 a").not(".js_gnav_switch2").on("mouseenter", this.switch2leave);
  
  this.$current = null;
  
  this.$switch1.on("mouseenter", this.switch1enter);
  this.$gnav_pc.on("mouseleave", this.switch1leave);
  
  //this.$switch1__target.css('display', 'flex');
  
  $(".g_nav_2__2").menuAim({
    activate: this.switch2enter,
    deactivate: this.switch2leave,
    rowSelector: ".g_nav_2__item"
  });
  
  console.groupEnd();
}



Gnav_A.prototype.switch2enter = function(row){
  console.group("switch2enter");
  var $a = $(row).find("a"),
      target = $a.data("target");
  if( target ){
    $a.addClass("is_active");
    $(target).removeClass("is_none");
  }
  console.groupEnd();
}



Gnav_A.prototype.switch2leave = function(e){
  console.group("switch2leave");
  this.$switch2.removeClass("is_active");
  this.$switch2__target.addClass("is_none");
  console.groupEnd();
}



Gnav_A.prototype.switch1enter = function(e){
  var self = this;
  this.switch1enterTimer = setTimeout(function(){
    console.group("switch1enter");
    self.$switch1__target.removeClass("is_open").stop(true,false).slideUp(0);
    var $me = $(e.currentTarget),
        target = $me.data("target"),
        $target = $(target);
    console.log("target", target);
    //$(target).removeClass("is_close");
    console.log("easeInOutSine");
    $target.stop(true,false).slideDown(self.opts.duration, "easeInOutSine", function(){
      $target.addClass("is_open");
    });
  }, 200);
  console.groupEnd();
}



Gnav_A.prototype.switch1leave = function(){
  console.group("switch1leave");
  clearTimeout( this.switch1enterTimer );
  //this.$current.addClass("is_close");
  //this.$current = null;
  //this.$switch1__target.addClass("is_close");
  this.$switch1__target.removeClass("is_open").stop(true,false).slideUp(0);
  console.groupEnd();
}
