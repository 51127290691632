$(function(){
  
  //this.$body = $("body");
  
  new Gnav_A();
  new Gnav_B();
  
  $(".js_accordion_2").each(function(index, el){
    new AZPLAN.Accordion_A({
      el: el,
      klass: ".js_accordion_2"
    });
  });
  
  $(".js_accordion_1").each(function(index, el){
    new AZPLAN.Accordion_A({
      el: el,
      klass: ".js_accordion_1"
    });
  });
  
});