/*
## Gnav_B
*/

function Gnav_B(opts){
  console.group("Gnav_B");
  _.bindAll(this, "toggle");
  this.opts = _.extend({
    duration: 300,
    easing: "easeInOutExpo"
  }, opts);
  
  this.$gnav_sp = $("#gnav_sp");
  this.$gnav_sp__toggle = $(".js_gnav_sp__toggle");
  
  console.log( this.$gnav_sp__toggle.length );
  this.$gnav_sp__toggle.click(this.toggle);
  
  console.groupEnd();
}



Gnav_B.prototype.toggle = function(e){
  console.group("Gnav_B toggle");
  this.$gnav_sp.stop(true,false).slideToggle(this.opts.duration, this.opts.easing);
  //this.$body.toggleClass("js_gnav_opened");
  console.groupEnd();
  return false;
}
