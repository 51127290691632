AZPLAN.Accordion_A = Backbone.View.extend({
  
  initialize: function(opts) {
    _.bindAll(this, "toggle", "open", "close");
    this.opts = _.extend({
      klass: ".js_accordion_a",
      duration: 500,
      easing: "easeInOutExpo"
    }, opts);
    
    this.$toggle = this.$(this.opts.klass + "__toggle");
    this.$contents = this.$(this.opts.klass + "__contents");
    
    this.$toggle.off("click").on("click", this.toggle);
    this.close();
  },
  
  toggle: function() {
    console.log("Accordion_A toggle");
    this.$contents.stop(true,false).slideToggle(this.opts.duration, this.opts.easing);
    this.$el.toggleClass("is_active");
    this.$toggle.toggleClass("is_active");
    return false;
  },
  
  open: function(){
    this.$contents.stop(true,false).slideDown(this.opts.duration, this.opts.easing);
    this.$el.addClass("is_active");
    this.$toggle.addClass("is_active");
    return false;
  },
  
  close: function(){
    this.$contents.stop(true,false).slideUp(this.opts.duration, this.opts.easing);
    this.$el.removeClass("is_active");
    this.$toggle.removeClass("is_active");
    return false;
  }
  
});
